$main-text-color: black;

.container a {
  text-decoration: none;
}

.container .cardContentContainer {
  text-align: center;
  box-shadow: none;
  &:last-child {
    padding: 8px;
  }
}

.container .cardTitle {
  color: $main-text-color;
  font-size: 1.5rem !important;
  padding: 8px 0;
}

.container .detailInfo {
  color: $main-text-color;
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0;
}

.container .cardDetailContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container .detailheader {
  color: #9e9e9e;
  margin: 0;
}

.container .riskName {
  margin: 8px 0;
  font-size: 18px;
  font-weight: bold;
}

.container .assetCardContent {
  text-align: center;
  box-shadow: none;
  color: $main-text-color;
  font-size: 14px;
}
